import { useStoreContext } from 'contexts';
import getRecommendedCountryCodes from 'lib/getRecommendedCountryCodes';
import IntlTelInput from 'react-intl-tel-input';

const stripLeadingZero = string => (string.startsWith('0') ? string.replace('0', '') : string);

const PhoneInput = ({
  value,
  onChange,
  placeholder,
  fieldName,
  className = '',
  required = false,
  alwaysAppendDialCode = false,
}) => {
  const { store, locale } = useStoreContext();

  const [defaultCountryCode] = getRecommendedCountryCodes(store, locale);

  return (
    <IntlTelInput
      fieldName={fieldName}
      telInputProps={{ required }}
      preferredCountries={['us', 'gb', 'nl', 'de']}
      defaultCountry={defaultCountryCode ? defaultCountryCode.toLowerCase() : null}
      value={value}
      onPhoneNumberChange={(_, newValue) => onChange(newValue)}
      onPhoneNumberBlur={(isValid, newValue, { dialCode: newDialCode }) => {
        if (newValue && (isValid || alwaysAppendDialCode) && !newValue.startsWith('+'))
          onChange(`+${newDialCode}${stripLeadingZero(newValue)}`);
      }}
      formatOnInit={false}
      nationalMode={false}
      containerClassName={`intl-tel-input ${className}`}
      placeholder={placeholder}
      inputClassName="input w-full"
    />
  );
};
export default PhoneInput;
